/**********   GRID  ************/
.grid {
    display: grid;
    gap: 5rem;
}

.grid--1-cols {
    grid-template-columns: 1fr;
}

.grid--2-cols {
    grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
    grid-template-columns: repeat(3, 1fr);
}
.grid--4-cols {
    grid-template-columns: repeat(4, 1fr);
}
.grid--5-cols {
    grid-template-columns: repeat(5, 1fr);
}
/**********   GRID  ************/
.process {
    position: relative;
}
.heading-process {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 2.5;
    color: #5b5b5b;
}
.process-icons {
    width: 8rem;
    height: 8rem;
    border: 3px solid rgba(0, 0, 0, 1);
    border-radius: 50%;
    position: relative;
    background-color: #fff;
    filter: brightness(100%);
    transition: all 0.5s ease;
    animation: process-icons 13s infinite;
    -webkit-animation: process-icons 13s infinite;
    &:hover {
        filter: brightness(95%);
        cursor: pointer;
    }
}
.process.active .process-icons {
    animation: none;
    -webkit-animation: none;
    background: #fff;
    border: 3px solid #000;
}
@keyframes process-icons {
    0% {
        background: #fff;
        border: 3px solid #000;
    }
    25% {
        background: #087001;
        border: 3px solid #23cf17;
    }
    50% {
        background: #087001;
        border: 3px solid #23cf17;
    }
    75% {
        background: #087001;
        border: 3px solid #23cf17;
    }
    85% {
        background: #087001;
        border: 3px solid #23cf17;
    }
    90% {
        background: #087001;
        border: 3px solid #23cf17;
    }
    91% {
        background: #fff;
        border: 3px solid #000;
    }
    100% {
        background: #fff;
        border: 3px solid #000;
    }
}
.process-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.8rem;
    z-index: 2;
}
.process-bar {
    position: absolute;
    height: 3px;
    width: 78%;
    background-color: rgba(0, 0, 0, 1);
    top: 14.5rem;
    left: 50%;
    transform: translateX(-50%);
}

.process-fill {
    position: absolute;
    height: 3px;
    width: 0%;
    background-color: #23cf17;
    top: 14.5rem;
    left: 10rem;
    transition: width 0.5s linear;
    animation: process-fill 13s infinite;
    -webkit-animation: process-fill 13s infinite;
    animation-delay: 1.5s;
}
.process.active .process-fill {
    animation: none;
    -webkit-animation: none;
}
@keyframes process-fill {
    0% {
        width: 0%;
    }
    21% {
        width: 20%;
    }
    40% {
        width: 40%;
    }
    60% {
        width: 60%;
    }
    82% {
        width: 80%;
    }
    90% {
        width: 82%;
    }
    100% {
        width: 0;
    }
}
.process-icon,
.process-icon-fill {
    background-repeat: no-repeat;
    background-size: cover;
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.process-icon-fill {
    opacity: 0;
    animation: process-icon-fill 13s infinite;
    -webkit-animation: process-icon-fill 13s infinite;
}
.process.active .process-icon-fill {
    opacity: 0;
    animation: none;
    -webkit-animation: none;
}
.process.active .process-icon {
    opacity: 1;
    animation: none;
    -webkit-animation: none;
}
.process-icon {
    opacity: 1;
    animation: process-icon 13s infinite;
    -webkit-animation: process-icon 13s infinite;
}
@keyframes process-icon-fill {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    91% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes process-icon {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    85% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    91% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.process-text {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.05rem;
    text-align: center;
    opacity: 0;
    transition: all 0.5s ease;
    animation: process-text 13s infinite;
    -webkit-animation: process-text 13s infinite;
}
.process.active .process-text {
    animation: none;
}
@keyframes process-text {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    91% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.process-content:nth-of-type(1) .process-icons,
.process-content:nth-of-type(1) .process-icon,
.process-content:nth-of-type(1) .process-icon-fill,
.process-content:nth-of-type(1) .process-text {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
}
.process-content:nth-of-type(2) .process-icons,
.process-content:nth-of-type(2) .process-icon,
.process-content:nth-of-type(2) .process-icon-fill,
.process-content:nth-of-type(2) .process-text {
    animation-delay: 3s;
    -webkit-animation-delay: 3s;
}
.process-content:nth-of-type(3) .process-icons,
.process-content:nth-of-type(3) .process-icon,
.process-content:nth-of-type(3) .process-icon-fill,
.process-content:nth-of-type(3) .process-text {
    animation-delay: 5s;
    -webkit-animation-delay: 5s;
}
.process-content:nth-of-type(4) .process-icons,
.process-content:nth-of-type(4) .process-icon,
.process-content:nth-of-type(4) .process-icon-fill,
.process-content:nth-of-type(4) .process-text {
    animation-delay: 7s;
    -webkit-animation-delay: 7s;
}
.process-content:nth-of-type(5) .process-icons,
.process-content:nth-of-type(5) .process-icon,
.process-content:nth-of-type(5) .process-icon-fill,
.process-content:nth-of-type(5) .process-text {
    animation-delay: 10s;
    -webkit-animation-delay: 10s;
}

@media (max-width: 992px) {
    .grid {
        gap: 0.5rem;
    }
}
@media (max-width: 767px) {
    @keyframes process-fill {
        0% {
            width: 0%;
        }
        21% {
            width: 20%;
        }
        40% {
            width: 40%;
        }
        60% {
            width: 60%;
        }
        82% {
            width: 78%;
        }
        90% {
            width: 78%;
        }
        100% {
            width: 0;
        }
    }
}
@media (max-width: 575px) {
    .grid {
        gap: 0rem;
    }
    .heading-process {
        font-size: 2.2rem;
    }
    .process-icons {
        height: 3rem;
        width: 3rem;
    }
    .process-icon,
    .process-icon-fill {
        height: 1.5rem;
        width: 1.5rem;
    }
    .process-text {
        font-size: 12px;
        padding-right: 5px;
    }
    .process-bar {
        top: 8rem;
        height: 2.5px;
    }
    .process-fill {
        top: 8rem;
        left: 6rem;
        height: 2.5px;
    }
}
@media (max-width: 475px) {
    @keyframes process-fill {
        0% {
            width: 0%;
        }
        21% {
            width: 16%;
        }
        40% {
            width: 35%;
        }
        60% {
            width: 56%;
        }
        82% {
            width: 75%;
        }
        90% {
            width: 75%;
        }
        100% {
            width: 0;
        }
    }
}
@media (max-width: 312px) {
    .heading-process {
        font-size: 2rem;
    }
    .process-icons {
        height: 2rem;
        width: 2rem;
    }
    .process-icon,
    .process-icon-fill {
        height: 1rem;
        width: 1rem;
    }
    .process-text {
        font-size: 9px;
    }
    .process-bar {
        top: 7rem;
        height: 2px;
    }
    .process-fill {
        top: 7rem;
        left: 3rem;
        height: 2px;
    }
}
