$backGound: var(--third-color);
$text-bold: var(--font-bold);
$primary-color: var(--primary-color);

.project-detail {
    background: $backGound;
    .detail {
        padding: 2em 0;
        text-align: justify;
        .title {
            text-transform: uppercase;
            text-align: center;
        }
    }
}
.benefits-truck-body {
    margin-top: 2em;
    margin-bottom: 2em;
}
.title-heading {
    text-align: center;
    font-size: 2.5em;
    text-transform: uppercase;
    color: #5b5b5b;
    margin-bottom: 1em;
}

.benefits-truck .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .sub-title {
        font-size: 1.5em;
        font-family: $text-bold;
        margin-bottom: 1em;
        strong {
            font-size: 1em;
            cursor: pointer;
            display: inline-block;
            &.active {
                color: $primary-color;
            }
            &:hover{
                color: $primary-color;
            }
        }
    }
}

.sub-benefits-truck {
    background: $primary-color;
    border-radius: 20px;
    padding: 20px 10px;
    overflow: hidden;
    color: #f0f0ef;
    animation: moving 1s;
    transition: all 0.5s;
    .sub-title {
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 10px;
    }
    &.active {
        display: block;
    }
}
@keyframes moving {
    from {
        transform: translateX(100px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
.sub-title-truck {
    display: flex;
    align-items: center;
    p {
        padding: 20px 0 20px 20px;
        text-align: justify;
        font-size: 1.1em;
    }
}
@media (max-width: 1250px) {
    .project-detail .detail .title {
        font-size: 4vw;
    }
}
@media (max-width: 992px) {
    .project-detail .detail .title {
        font-size: 3.5vw;
    }
}
@media (max-width: 768px) {
    .details{
        font-size: 14px;
    }
    .image-detail{
        display: none;
    }
    .project-detail .detail .title {
        font-size: 5vw;
    }
}

@media (max-width: 575px) {
    .project-detail{
        padding: 10px 15px;
        .title {
            font-size: 2.3em;
        }
    }
    .title-heading {
        font-size: 1.7em;
    }
}
@media (max-width: 450px) {
    .project-detail .detail .title {
        font-size: 7vw;
    }
}
@media (max-width: 415px) {
    .title-heading {
        font-size: 1.5em;
    }
}

@media (max-width: 340px) {
    .title-heading {
        font-size: 1.3em;
    }
}