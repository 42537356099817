.navbar-brand {
    padding: 20px 0 !important;
    border: unset !important;
}
@media screen and (min-width: 1650px) {
    .navbar-brand {
        height: 90px;
    }
}
@media (max-width: 992px) {
    .navbar-brand {
        height: auto;
        width: 100px;
    }
}
