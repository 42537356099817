.reason{
    overflow: hidden;
}
.tabm {
    background-color: #fff;
    padding: 1.25em 0;
    color: #000;
    border-radius: 10px;
    position: relative;
    transition: 0.5s;
    margin-bottom: 2.5em;
    cursor: pointer;
    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #fff;
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        transition: 0.5s;
    }
}
.h2-custom {
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 0.5em;
    font-family: var(--third-bold);
}
.tabm.active {
    background-color: var(--primary-color);
    color: #fff;
    &::before {
        top: 100%;
        opacity: 1;
        visibility: visible;
        border-top: 20px solid #087001;
    }
    .tabm-icon {
        opacity: 1;
    }
    .tabm-icon-fill {
        opacity: 0;
    }
}

.image {
    position: relative;
    height: 5em;

    .tabm-icon,
    .tabm-icon-fill {
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3em;
        height: 3em;
        transition: 0.5s;
    }
}
.content_tab {
    display: grid;
    grid-template-columns: 3fr 9fr;
    animation: moving 0.5s ease;
    min-height: 120px;
    height: 100%;
    &.hidden {
        display: none;
    }
    .tab-img img {
        border-radius: 10px 0 0 10px;
    }
    .text {
        border-radius: 0 10px 10px 0;
        background-color: var(--primary-color);
        color: #fff;
        text-align: justify;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            font-size: 1em;
            padding: 0 1.25em;
        }
    }
}
@keyframes moving {
    from {
        transform: translateX(50px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@media (max-width: 992px) {
    .tabm {
        padding: 5px 0;
    }
}
@media (max-width: 768px) {
    .tabm {
        margin-bottom: 1em;
        &::before {
            display: none;
        }
    }
    .content_tab {
        margin-top: 20px;
        .text {
            padding: 20px 0;
            p {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 433px) {
    .h2-custom {
        font-size: 18px;
    }
    .content_tab .text {
        padding: 10px 0;
        p {
            font-size: 12px;
        }
    }
}
@media (max-width: 401px) {
    .h2-custom {
        font-size: 16px;
    }
    .image {
        height: 4em;
        .tabm-icon,
        .tabm-icon-fill {
            width: 2em;
            height: 2em;
        }
    }
}
@media (max-width: 361px) {
    .h2-custom {
        font-size: 14px;
    }
    .image {
        height: 3em;
    }
}
