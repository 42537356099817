// gallery
.gallery-item {
    overflow: hidden;
    border: 3px solid #454035;
    img {
        transition: all ease-in-out 0.4s;
    }
    &:hover img {
        transform: scale(1.1);
    }
}
.gallery-lightbox {
    display: block;
    height: 100%;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
