$custom-text-color: #fff;

.menu-item + .menu-item {
    width: 100%;
    text-align: -webkit-right;
    font-size: 1.125em;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 2px solid rgba(134, 149, 177, 0.25);
}
.menu-item:first-of-type {
    width: 100%;
    text-align: right;
    font-size: 1.125em;
    margin-top: 10px;
    padding-top: 10px;
}

.menu-item {
    font-size: 1em;
    .btn-find-quickly {
        font-size: 1em;
        color: var(--primary-color);
        background: transparent;
        padding: 0;
        border: unset;
        &:hover {
            opacity: 0.6;
        }
    }
    .nav-links {
        color: $custom-text-color;
        background: transparent;
        transition: 0.3s ease;
        padding: 0;
        border: unset;
        &:hover {
            color: var(--primary-color);
        }
    }
}
.navbar-menu {
    position: unset;
    background: transparent;
    border: unset;
    font-size: 16px;
    text-align: right;
    padding: 0;

    .menu-item {
        width: 100%;
        text-align: -webkit-right;
        font-size: 1em !important;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 2px solid rgba(134, 149, 177, 0.25);
        line-height: 2.4rem;
    }
    .sub-dropdown-item {
        color: $custom-text-color;
        width: 100%;
        font-size: 1em;
        padding: 0;
        padding-right: 0.9375em;
        transition: all 0.3s;
        border: none;
        &:hover {
            background: transparent;
            color: var(--primary-color);
        }
        & > span{
            font-size: 15px;
        }
    }
}

.overlay-nav {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
}
