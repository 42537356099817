$primary-color: var(--primary-color);
.collapsible {
    max-width: 450px;
    overflow: hidden;
    margin: auto;
    margin-bottom: 20px;
    input {
        display: none;
    }
    label {
        position: relative;
        background: #fff;
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.1), 0 4px 11px 0 rgba(0, 0, 0, 0.08);
        display: block;
        margin-bottom: 10px;
        cursor: pointer;
        color: #1c1c6b;
        padding: 15px 0;
        border-radius: 4px;
        z-index: 1;
        &::after {
            content: '';
            position: absolute;
            background-image: url('./right-arrow.png');
            background-repeat: no-repeat;
            right: 15px;
            top: 20px;
            width: 18px;
            height: 18px;
            transition: all 0.3s ease;
        }
    }
}
.collapsible input:checked + label::after {
    transform: rotate(90deg);
}
.collapsible label .title {
    color: #000;
    font-size: 1.2em;
    font-family: var(--font-bold);
    padding-left: 30px;
}
.collapsible .collapsible-text {
    max-height: 1px;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    top: -80px;
    opacity: 0;
    transition: all 0.3s ease;
}
.collapsible input:checked ~ .collapsible-text {
    max-height: unset;
    opacity: 1;
    top: 0;
}
.sub-benefits-truck {
    background: $primary-color;
    border-radius: 4px;
    padding: 20px 10px;
    overflow: hidden;
    color: #f0f0ef;
    animation: moving 1s;
    transition: all 0.5s;
    .sub-title {
        text-align: center;
        font-size: 1.4em;
        margin-bottom: 10px;
    }
    &.active {
        display: block;
    }
    .title-sub-benefits-truck{
        text-align: center;
        font-size: 1.5em;
    }
    p{
        text-align: justify;
        padding: 10px;
        font-size: 1.1em;
    }
}
@keyframes moving {
    from {
        transform: translateX(100px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}