$text-banner: var(--font-banner-bold);
$text-bold: var(--font-bold);
$primary-color: var(--primary-color);

.banner {
    height: calc(100vh - 78px);
    &.swiper-silde {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        overflow: hidden;
        position: relative;
    }
}
.sub-title-banner-1,
.sub-title-banner-2,
.sub-title-banner-3 {
    position: absolute;
}
.sub-title-banner-1 {
    width: 35%;
    font-size: 32px;
    bottom: 3em;
    left: 4em;
    color: #262626;
    cursor: pointer;
    transition: 0.5s;
    text-align: left;
    h1 {
        font-size: 1.6em;
        font-family: $text-banner;
        line-height: 1;
        text-transform: uppercase;
    }
    p {
        font-family: $text-bold;
        font-size: 1em;
    }
    .view-more {
        font-family: $text-bold;
        text-decoration: underline;
        text-align: right;
        font-size: 0.8em;
        padding-right: 40px;
        a {
            color: #262626;
            &:hover {
                color: $primary-color;
                text-decoration: underline $primary-color;
            }
        }
    }
}

.sub-title-banner-2 {
    width: 46%;
    font-size: 34px;
    top: 4em;
    left: 2em;
    color: #000;
    cursor: pointer;
    transition: 0.5s;
    text-align: left;
    h2 {
        font-size: 1em;
        font-family: $text-banner;
        line-height: 1.5;
        text-align: center;
    }
    ul {
        font-family: $text-bold;
        padding-left: unset;
    }
    p {
        font-size: 20px;
        font-family: $text-bold;
    }
}
.sub-title-banner-2-animation {
    animation-name: banner-index;
    animation-delay: 6.3s;
    animation-duration: 2s;
    animation-fill-mode: forwards !important;
    -webkit-animation-fill-mode: forwards !important;
    animation-iteration-count: 1 !important;
    -webkit-animation-iteration-count: 1 !important;
    -ms-animation-iteration-count: 1 !important;
    @keyframes banner-index {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.sub-title-banner-3 {
    width: 49%;
    top: 50%;
    left: 5%;
    font-size: 32px;
    transform: translateY(-50%);
    text-align: center;
    h2 {
        color: $primary-color;
        font-size: 1.6em;
        font-family: $text-banner;
        line-height: 1.5;
        text-align: center;
    }
    p {
        font-size: 0.9em;
        font-family: $text-bold;
    }
}

@media (min-width: 1440px) {
    .sub-title-banner-1 {
        bottom: 135px;
        left: 200px;
        width: 30%;
    }
}
@media (min-width: 1599px) {
    .sub-title-banner-2 {
        left: 2em;
        font-size: 40px;
        p {
            font-size: 24px;
        }
    }
}
@media (min-width: 1679px) {
    .sub-title-banner-1 {
        bottom: 120px;
    }
}
@media (min-width: 1919px) {
    .sub-title-banner-1 {
        bottom: 4.5em;
        left: 8em;
    }
    .sub-title-banner-3 {
        width: 50%;
    }
}
@media (max-width: 1401px) {
    .sub-title-banner-1 {
        bottom: 150px;
    }
}
@media (max-width: 1367px) {
    .sub-title-banner-1 {
        bottom: 130px;
        left: 100px;
        font-size: 26px;
    }
}
@media (max-width: 1281px) {
    .sub-title-banner-1 {
        bottom: 6em;
        left: 2.5em;
    }
    .sub-title-banner-2 {
        left: 1.5em;
        top: 1.5em;
        p {
            font-size: 18px;
        }
    }
}
@media (max-width: 1281px) and (max-height: 650px) {
    .sub-title-banner-1 {
        bottom: 55px;
        left: 140px;
        font-size: 24px;
    }
}
@media (max-width: 1200px) {
    .sub-title-banner-1 {
        bottom: 4em;
        left: 2.5em;
    }
    .sub-title-banner-2 {
        font-size: 23px;
    }
}
@media (max-width: 1025px) {
    h1 {
        font-size: 36px;
    }
    .sub-title-banner-1 {
        bottom: 3em;
        left: 2.5em;
        font-size: 24px;
    }
    .sub-title-banner-2 {
        top: 3em;
        font-size: 25px;
        p {
            font-size: 16px;
        }
    }
    .sub-title-banner-3 {
        h2{
            font-size: 1.4em;
        }
        p {
            font-size: 0.7em;
        }
    }
}
@media (max-width: 1025px) and (min-height: 1365px) {
    .sub-title-banner-1 {
        bottom: 10em;
        left: 1em;
    }
    .sub-title-banner-2 {
        top: 12em;
        p {
            font-size: 18px;
        }
    }
}
@media (max-width: 992px) {
    .banner {
        height: calc(100vh - 70px);
    }
    .sub-title-banner-1 {
        bottom: 11em;
        left: 1em;
    }
}
@media (max-width: 992px) and (max-height: 620px) {
    .sub-title-banner-1 {
        bottom: 2.5em;
        left: 4em;
        font-size: 22px;
    }
    .sub-title-banner-2 {
        top: 3em;
        font-size: 24px;
        p {
            font-size: 18px;
        }
    }
    .sub-title-banner-3 {
        h2 {
            font-size: 1.5em;
        }
    }
}
@media (max-width: 840px) {
    .sub-title-banner-1 {
        h1 {
            font-size: 1.3em;
        }
    }
    .sub-title-banner-2 {
        font-size: 18px;
    }
    .sub-title-banner-3 {
        left: 7%;
        font-size: 28px;
    }
}
@media (max-width: 769px) {
    .sub-title-banner-1 {
        bottom: 9em;
        left: 0.5em;
        h1 {
            font-size: 1.15em;
        }
        p {
            font-size: 0.8em;
        }
        .view-more {
            padding-right: 10px;
            font-size: 0.8em;
        }
    }
    .sub-title-banner-2 {
        font-size: 16px;
        p {
            font-size: 16px;
        }
    }
    .sub-title-banner-3 {
        font-size: 26px;
        h1 {
            font-size: 1.3em;
        }
    }
}
@media (max-width: 769px) and (max-height: 620px) {
    .sub-title-banner-1 {
        bottom: 4em;
    }
}
@media (max-width: 605px) {
    .sub-title-banner-1 {
        width: 55%;
        font-size: 28px;
        bottom: 7em;
        left: 0.5em;
        p {
            font-size: 0.8em;
        }
    }
    .sub-title-banner-2 {
        width: 63%;
        p {
            width: 70%;
            font-size: 16px;
            margin-bottom: 8px;
        }
    }
    .sub-title-banner-3 {
        width: 60%;
        left: 8%;
        font-size: 26px;
    }
}
@media (max-width: 605px) and (max-height: 620px) {
    .sub-title-banner-1 {
        bottom: 1em;
    }
}

@media (max-width: 575px) {
    .sub-title-banner-1 {
        width: 55%;
        font-size: 25px;
        bottom: 5em;
        left: 0.5em;
        h1 {
            font-size: 1.2em;
        }
    }
    .sub-title-banner-2 {
        width: 70%;
        font-size: 20px;
        p {
            width: 75%;
        }
    }
    .sub-title-banner-3 {
        width: 58%;
        top: 60%;
        left: 2%;
        font-size: 20px;
    }
}
@media (max-width: 575px) and (max-height: 620px) {
    .sub-title-banner-1 {
        bottom: 1.3em;
    }
}
@media (max-width: 481px) {
    .sub-title-banner-1 {
        width: 57%;
        font-size: 24px;
        bottom: 6.5em;
    }
    .sub-title-banner-3 {
        width: 55%;
        font-size: 19px;
    }
}
@media (max-width: 481px) and (max-height: 620px) {
    .sub-title-banner-1 {
        bottom: 2.5em;
    }
}
@media (max-width: 433px) {
    .sub-title-banner-1 {
        font-size: 22px;
    }
    .sub-title-banner-2 {
        font-size: 16px;
        p {
            font-size: 14px;
        }
    }
    .sub-title-banner-3 {
        font-size: 17px;
    }
}
@media screen and (max-width: 415px) {
    .sub-title-banner-1 {
        width: 60%;
        font-size: 20px;
    }
    .sub-title-banner-2 {
        font-size: 14px;
        p {
            font-size: 13px;
        }
    }
    .sub-title-banner-3 {
        font-size: 16px;
    }
}
@media (max-width: 401px) {
    .sub-title-banner-1 {
        font-size: 20px;
    }
    .sub-title-banner-2 {
        font-size: 17px;
        width: 81%;
    }
    .sub-title-banner-3 {
        font-size: 15px;
    }
}
@media (max-width: 361px) {
    .sub-title-banner-1 {
        font-size: 19px;
        h1 {
            font-size: 1.18em;
        }
    }
    .sub-title-banner-2 {
        top: 3em;
        width: 75%;
        font-size: 14px;
        p {
            font-size: 12.5px;
        }
    }
    .sub-title-banner-3 {
        font-size: 15px;
        width: 58%;
    }
}
@media (max-width: 321px) {
    .sub-title-banner-1 {
        font-size: 16px;
    }
    .sub-title-banner-3 {
        font-size: 13px;
        width: 60%;
    }
}
