.widget-contact {
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 9000;
    .icon-contact {
        height: 45px;
        width: 45px;
        cursor: pointer;
    }
    .contact-phone {
        position: relative;
        margin-bottom: 20px;
        img {
            animation: 2s infinite phone-animation;
        }
    }
}
@keyframes phone-animation {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(25deg);
    }
    20% {
        transform: rotate(-25deg);
    }
    30% {
        transform: rotate(25deg);
    }
    40% {
        transform: rotate(-25deg);
    }
    50% {
        transform: rotate(0deg);
    }
}
.sub-phone {
    position: fixed;
    background: var(--primary-color);
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    bottom: 145px;
    transform: translateY(-5px);
    right: -100%;
    opacity: 0;
    visibility: hidden;
    transition: 1.5s;
    &.active {
        opacity: 1;
        visibility: visible;
        right: 73px;
    }
}

@media (max-width: 768px) {
    .pc {
        display: none;
    }
    .mobile {
        display: block !important;
        margin-bottom: 10px;
        img {
            animation: 2s infinite phone-animation;
        }
    }
}
@media (max-width: 475px) {
    .widget-contact {
        bottom: 60px;
        right: 10px;
        .icon-contact {
            height: 35px;
            width: 35px;
            cursor: pointer;
        }
    }
}
