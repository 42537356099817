.loading-page{
    position: fixed;
    top: 0;
    left: 0;
    inset: 0;
    background: #ccc;
    background-size: cover;
    width: 100%;
    height: 100vh;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.show{
    z-index: 9999;
}

.hide{
    z-index: -1;
    opacity: 0;
}