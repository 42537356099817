.wrapper{
    padding: 6px 12px;
    font-size: 1em;
    background: transparent;
    border-radius: 4px;
    border: 3px solid transparent;
    transition: .3s linear;

    + .wrapper {
        margin-left: 8px;
    }
}

.outline{
    border-color: var(--primary-color);
    color: currentColor;

    &:hover{
        background-color: var(--primary-color);
        color: #fff;
    }
    &:hover > span {
        color: #fff;
    }
}