.modal-container {
    background-color: #fff;
    position: fixed;
    z-index: 10001;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 250px calc(100% - 250px);
    grid-template-columns: 250px 500px;
    grid-template-rows: 350px;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 10px;
    overflow: hidden;
}
.group-modal {
    margin-bottom: 20px;
}
.modal-btn-close {
    position: absolute;
    top: 15px;
    right: 20px;
    height: 17px;
    width: 17px;
    cursor: pointer;
    .line-1,
    .line-2 {
        height: 3px;
        width: 100%;
        border-radius: 55px;
        background-color: #5b5b5b;
        position: absolute;
        transition: all 0.5s ease;
        top: 50%;
    }
    .line-1 {
        transform: rotate(45deg);
    }
    .line-2 {
        transform: rotate(-45deg);
    }
    &:hover {
        .line-1,
        .line-2 {
            background-color: var(--primary-color);
        }
    }
}

.h5-custom {
    text-align: center;
    font-size: 1.2em;
    padding: 20px 0;
    color: var(--primary-color);
}
.groups-modal {
    margin: 20px 0 40px 50px;
    overflow-y: overlay;
    height: 160px;
    &::-webkit-scrollbar-thumb {
        background-color: #5b5b5b;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    label {
        color: #000 !important;
        padding-left: 5px;
        text-transform: capitalize;
        display: inline;
    }
}
.group-modal:nth-child(even) {
    padding-left: 30px;
}
.modal-submit {
    background: var(--primary-color);
    &:hover {
        opacity: 0.9;
        background: var(--primary-color);
        border-color: unset;
        border: 3px solid transparent;
    }
    &:disabled {
        background: var(--primary-color);
        border-color: unset;
        border: 3px solid transparent;
    }
}
.title-label{
    text-transform: capitalize;
}
@media (max-width: 769px) {
    .modal-container {
        display: flex;
        flex-direction: column;
        width: 450px;
    }
    .image-pc {
        display: none;
    }
    .modal-panel-mobile {
        display: block !important;
        color: #fff;
        background: #000;
        position: relative;
        padding: 14px 10px;
    }
    .modal-content {
        position: unset;
    }
    .panel-title-logo {
        font-size: 2em;
        font-family: 'Montserrat ExtraBold';
        text-align: center;
        color: var(--primary-color);
    }
    .panel-content {
        font-family: Photograph Signature;
        text-align: center;
        letter-spacing: 5px;
        font-size: 1.5em;
    }
    .groups-modal {
        margin: 20px 0 40px 30px;
        height: 120px;
         &::-webkit-scrollbar {
            width: 0px;
        }
    }
    .submit {
        padding-bottom: 30px;
    }
}

@media (max-width: 475px) {
    .modal-container {
        width: 350px;
        font-size: 12px;
    }
    .groups-modal {
        margin: 10px 0 10px 20px;
    }
}

@media (max-width: 375px) {
    .modal-container {
        width: 300px;
        font-size: 10px;
    }
    .groups-modal {
        margin: 10px 0 10px 10px;
    }
}
