$primary-color: var(--primary-color);
$background-color: var(--third-color);
$text-bold: var(--font-bold);

.addBackGround {
    background: $background-color;
}
.sub-product {
    padding-bottom: 2.5rem;
}

.product-image {
    max-width: 75%;
    height: auto;
    margin: 3.75em auto 0 auto;
    object-fit: cover;
    figure {
        height: auto;
        max-width: 100%;
        overflow: hidden;
    }
}
.title {
    padding: 0 1.875em 0 2.5em;
    margin-top: 2em;
}
.sub-title {
    text-align: justify;
}
.h2-custom {
    font-size: 32px;
    margin-bottom: 2rem;
    a {
        font-size: 1em;
        font-family: $text-bold;
        text-transform: uppercase;
        position: relative;
        border-bottom: 3px solid $primary-color;
        height: 100%;
        &:hover {
            color: $primary-color;
        }
    }
}
.error-message {
    text-align: center;
    font-family: var(--font-bold);
}
@media (max-width: 1300px) {
    .h2-custom {
        font-size: 28px;
    }
}
@media (max-width: 1150px) {
    .h2-custom {
        font-size: 24px;
    }
}
@media (max-width: 1005px) {
    .h2-custom {
        font-size: 20px;
    }
}
@media (max-width: 768px) {
    .addBackGround {
        background: unset;
    }
    .sub-product:nth-of-type(odd) {
        background: $background-color;
    }
    .h2-custom {
        font-size: 24px;
    }
}
@media (max-width: 475px) {
    .h2-custom {
        font-size: 18px;
    }
}
@media (max-width: 400px) {
    .h2-custom {
        font-size: 16px;
    }
}
@media (max-width: 321px) {
    .h2-custom {
        font-size: 18px;
    }
}
