$primary-color: var(--primary-color);
$text-bold: var(--font-bold);
.intro-inner {
    margin: auto;
    text-align: center;
    .thicker-hr {
        width: 80%;
        height: 3px;
        background-image: linear-gradient(to left, #23cf17, #1fc214, #087001);
        margin: 10px auto;
    }
    p {
        font-size: 18px;
        margin-bottom: unset;
    }
}
.intro-title {
    font-family: $text-bold;
    display: block;
    cursor: pointer;
    transition: 0.3s ease;
    font-size: 1.75em;
    &:hover {
        color: $primary-color;
    }
}

.sub-intro {
    display: flex;
    font-size: 15px;
    text-align: justify;
    margin: 20px 0;
    .image {
        width: 8%;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        object-fit: scale-down;
    }
}
.sub-intro:last-of-type .image {
    width: 48px;
    height: 43px;
}

@media (max-width: 768px) {
    .sub-intro:last-of-type .image {
        width: 55px;
        height: 45px;
    }
}

@media (max-width: 575px) {
    .sub-intro .image {
        width: 10%;
        height: 45px;
    }
    .sub-intro:last-of-type .image {
        width: 50px;
    }
}
@media (max-width: 499px) {
    .intro-title {
        font-size: 1.4em;
    }
    .sub-intro .image {
        width: 12%;
        height: 45px;
    }
    .sub-intro:last-of-type .image {
        width: 50px;
    }
}
@media (max-width: 425px) {
    .sub-intro .image {
        width: 13%;
    }
    .sub-intro:last-of-type .image {
        width: 53px;
    }
}
@media (max-width: 405px) {
    .sub-intro .image {
        width: 15%;
        height: 45px;
    }
    .sub-intro:last-of-type .image {
        width: 58px;
    }
}
@media (max-width: 361px) {
    .intro-inner {
        font-size: 13px;
    }
    .intro-title {
        font-size: 1.3em;
    }
    .intro-inner p {
        font-size: 14px;
    }
    .sub-intro .image {
        width: 14%;
    }
    .sub-intro:last-of-type .image {
        width: 46px;
    }
}
