.nav-mobile{
    z-index: 1;
}
.nav-menu {
    color: #fff;
    opacity: 0;
    position: fixed;
    right: 0;
    top: -126%;
    gap: 0;
    flex-direction: column;
    align-items: end;
    padding: 7em 6.375em 0;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    width: 60%;
    min-height: 100%;
    text-align: center;
    transition: 0.5s ease-in-out;
    overflow-y: scroll;
}
.nav-menu.active {
    opacity: 1;
    top: 0%;
}

.hamburger {
    display: block;
    text-align: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    padding: 6px;
}
.hamburger.active .bar:nth-child(2) {
    opacity: 0;
}
.hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
    background-color: #fff;
}
.hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
    background-color: #fff;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: var(--primary-color);
    border-radius: 30px;
}

@media (max-width: 768px) {
    .nav-menu{
        width: 70%;
    }
}

@media (max-width: 575px) {
    .nav-menu{
        width: 100%;
    }
}

@media (max-width: 481px) {
    .nav-menu{
        padding: 80px 80px 0;
    }
}

@media (max-width: 441px) {
    .nav-menu{
        padding: 80px 50px 0;
    }
}
