$text-primary: var(--primary-color);
.title-banner {
    color: #fff;
}
.overlay-banner {
    position: relative;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background: #000;
        pointer-events: none;
    }
}
.service-packaging {
    margin-top: 3em;
    p {
        margin-bottom: 1em;
    }
}
.blox-icon-title {
    display: flex;
    gap: 1em;
    img {
        width: 50px;
        height: auto;
    }
    h3 {
        font-size: 1.7em;
        width: 100%;
        border-bottom: 2px solid #000;
    }
}
.description-usage-instructions {
    margin-top: 1.5em;
    text-align: justify;
    font-size: 16px;
}

.image-service {
    width: 70%;
    margin: 2em auto;
    .image-caption {
        text-align: center;
        margin-top: 10px;
    }
}

.h2-custom {
    font-size: 2em;
    color: $text-primary;
}

@media (max-width: 575px) {
    .service-packaging {
        margin-top: 1.5em;
        h3 {
            margin-right: 10px;
        }
    }
    .image-service {
        width: 90%;
        margin: 1em auto;
    }
    .h2-custom {
        font-size: 1.5em;
    }
    .description-usage-instructions {
        margin-top: 1em;
        font-size: 15px;
    }
}
