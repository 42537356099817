$text-font: var(--font-bold);
$text-color: var(--primary-color);

.footer {
    background-color: #d9d9d9;
    border-radius: 60px 60px 0 0;
    position: relative;
    padding-bottom: 1.25em;
    margin-top: 20em;
    bottom: 0;
}
.sub-footer {
    padding-top: 4.5em;
}
.first-footer img {
    width: 16em;
    height: 3em;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.first-footer .h3-custom {
    font-size: 1.5em;
    color: $text-color;
    font-family: $text-font;
    margin: 0.5em 0;
}
.first-footer .sub-title {
    font-family: $text-font;
}

.imformation-title {
    text-align: center;
    font-family: $text-font;
    font-size: 1.5em;
}

.imformation-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0.625em;
    img {
        width: 1.7em;
        padding-right: 0.5em;
        object-fit: contain;
    }
}
.map {
    width: 100%;
    height: 180px;
    border-radius: 20px;
    transform: translateY(1em);
}

.truck-footer {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    transform: translateY(-85%);
    img {
        width: 70%;
    }
}

@media (min-width: 1440px) {
    .first-footer .sub-title {
        width: 90%;
    }
    .footer .first-footer img {
        margin-right: 40%;
    }
    .footer {
        margin-top: 25em;
    }
}

@media (max-width: 1025px) {
    .first-footer .h3-custom {
        font-size: 1.2em;
    }
}

@media (max-width: 992px) {
    .footer {
        margin-top: 15em;
    }
    .first-footer img {
        width: auto;
        height: 2em;
    }
    .first-footer .h3-custom {
        font-size: 0.9em;
    }
    .first-footer .sub-title {
        font-size: 11px;
    }
    .imformation-footer {
        font-size: 12px;
    }
    .map {
        height: 145px;
    }
}

@media (max-width: 768px) {
    .footer {
        margin-top: 3em;
    }
    .sub-footer {
        padding-top: 1.5em;
    }
    .first-footer {
        text-align: center;
        margin-bottom: 1em;
    }
    .first-footer img {
        height: 2em;
    }
    .first-footer .h3-custom {
        font-size: 2em;
    }
    .first-footer .sub-title {
        font-size: 18px;
    }
    .second-footer {
        font-size: 20px;
    }
    .imformation-footer {
        font-size: 18px;
    }
    .imformation-footer:last-of-type {
        white-space: nowrap;
    }
    .truck-footer {
        display: none;
    }
    .map {
        height: 300px;
        width: 100%;
    }
}

@media (max-width: 575px) {
    .first-footer {
        margin-bottom: 1em;
    }
    .first-footer .h3-custom {
        font-size: 30px;
    }
    .first-footer .sub-title {
        font-size: 16px;
    }
    .map {
        height: 200px;
    }
}

@media (max-width: 481px) {
    .first-footer .h3-custom {
        font-size: 22px;
    }
    .imformation-title {
        font-size: 1em;
    }
}
@media (max-width: 433px) {
    .imformation-footer {
        font-size: 15px;
    }
}

@media (max-width: 401px) {
    .first-footer .h3-custom {
        font-size: 21px;
    }
    .imformation-footer {
        font-size: 13px;
    }
    .map {
        height: 150px;
    }
}

@media (max-width: 321px) {
    .first-footer .h3-custom {
        font-size: 18px;
    }
    .first-footer .sub-title {
        font-size: 14px;
    }
    .imformation-footer:last-of-type {
        white-space: unset;
    }
}
