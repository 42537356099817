$backGound: var(--third-color);
$text-bold: var(--font-bold);
$primary-color: var(--primary-color);

.structure-truck-body {
    margin-top: 4em;
}
.content-conversion-service {
    margin-bottom: 3em;
}
.conversion-service {
    background: #82ae41;
    text-align: center;
    font-size: 0.9em;
    font-family: $text-bold;
    padding: 4rem 0;
    text-transform: uppercase;
    border-radius: 7px;
    cursor: pointer;
    border: 2px solid #212121;
    letter-spacing: 0.1em;
    transition: 0.5s;
    position: relative;
    white-space: nowrap;
    &:hover {
        letter-spacing: 0.2em;
        opacity: 0.9;
        color: #fff;
        i::before {
            width: 40px;
            left: 20%;
        }
        i::after {
            width: 40px;
            left: 80%;
        }
    }
    i {
        position: absolute;
        inset: 0;
        display: block;
        &::before {
            content: ' ';
            position: absolute;
            top: -2px;
            left: 80%;
            width: 20px;
            height: 2px;
            background: #f8f8f8;
            transform: translateX(-50%) skewX(325deg);
            transition: 0.5s;
        }
        &::after {
            content: ' ';
            position: absolute;
            bottom: -2px;
            left: 20%;
            width: 20px;
            height: 2px;
            background: #f8f8f8;
            transform: translateX(-50%) skewX(325deg);
            transition: 0.5s;
        }
    }
    &.active {
        letter-spacing: 0.2em;
        opacity: 1;
        background: var(--primary-color);
        color: #fff;
    }
}
.dadsub-structure-truck-body {
    animation: moving 1s;
}
@keyframes moving {
    from {
        transform: scale(0.9    );
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
.sub-structure-truck-body {
    padding: 0 10px;
    padding-bottom: 5em;
    .title {
        text-transform: uppercase;
        text-align: left;
        font-family: var(--font-bold);
        padding: 15px 5px;
        white-space: nowrap;
        font-size: 1em;
        overflow: hidden;
        text-overflow: ellipsis;
        word-spacing: 3px;
    }
    .sub-title {
        text-align: justify;
        padding: 0 5px;
    }
}
.image-structure-truck {
    height: 200px;
    object-fit: cover;
    width: 100%;
}
@media (max-width: 575px) {
    .conversion-service{
        padding: 2em 0;
    }
    .sub-structure-truck-body{
        padding-bottom: 2em;
        .title{
            font-size: 1.2em;
            padding-bottom: 5px;
        }
    }
}

@media (max-width: 450px) {
    .sub-structure-truck-body{
        padding-bottom: 1em;
    }
    .conversion-service{
        font-size: 0.75em;
    }
}

@media (max-width: 380px) {
    .conversion-service{
        font-size: 0.65em;
    }
}

@media (max-width: 320px) {
    .conversion-service{
        font-size: 0.6em;
    }
}