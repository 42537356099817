@import 'normalize.css';

@font-face {
    font-family: 'Montserrat ExtraBold';
    src: url('/assets/fonts/Montserrat-ExtraBold.ttf') format('TrueType');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F5 Bold';
    src: url('/assets/fonts/FS-Magistral-Bold.ttf') format('TrueType');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'F5 Book';
    src: url('/assets/fonts/FS\ Magistral-Book.ttf') format('TrueType');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans Regular';
    src: url('/assets/fonts/OpenSans-Regular.ttf') format('TrueType');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Photograph Signature';
    src: url('/assets/fonts/Photograph-Signature.ttf') format('TrueType');
    font-weight: normal;
    font-display: swap;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    user-select: none;
}
.clear {
    clear: both;
}

:root {
    --font-bold: 'F5 Bold';
    --font-banner-bold: 'Montserrat ExtraBold';
    --primary-font: 'OpenSans Regular';
    --third-bold: 'F5 Book';
    --primary-color: #087001;
    --second-color: #5b5b5b;
    --third-color: #d4e4bc;
    --text-color: #000;
}
html {
    font-size: 62.5%;
}
body {
    font-family: var(--primary-font);
    position: relative;
    color: #000;
    background-color: #f8f8f8;
    overflow-x: hidden;
    font-size: 1.6rem;
    text-rendering: optimizeSpeed;
    display: flex;
    flex-direction: column;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: #000;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}
h1 {
    font-family: var(--font-banner-bold);
    font-size: 3.125em;
}

h2,
h3,
h4,
h5,
strong {
    font-family: var(--font-bold);
}

.f5-font {
    font-family: var(--font-bold);
}

.hidden {
    display: none;
}

.no-scroll {
    overflow: hidden;
}

.primary-color {
    color: var(--primary-color);
}
.zoom {
    transform: scale(1);
    transition: 0.5s;
}
.zoom:hover {
    transform: scale(1.2);
}

button,
input {
    outline: none;
    border: none;
}

/**********   GRID  ************/
.grid {
    display: grid;
    gap: 5rem;
}

.grid--1-cols {
    grid-template-columns: 1fr;
}

.grid--2-cols {
    grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
    grid-template-columns: repeat(3, 1fr);
}
.grid--4-cols {
    grid-template-columns: repeat(4, 1fr);
}
.grid--5-cols {
    grid-template-columns: repeat(5, 1fr);
}

html *::-webkit-scrollbar {
    width: 7px;
    border-radius: 0;
}

@media (max-width: 992px) {
    .culture-banner .grid {
        gap: 1rem;
    }
}
@media (max-width: 576px) {
    .grid--3-cols {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Track */
html *::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0;
}

/* Handle */
html *::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 0;
}

/* Handle on hover */
html *::-webkit-scrollbar-thumb:hover {
    background: #5b5b5b;
}

/* preload */
#preloader {
    position: fixed;
    inset: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
    transition: all 0.6s ease-out;
    width: 100%;
    height: 100vh;
}

#preloader:before,
#preloader:after {
    content: '';
    position: absolute;
    border: 4px solid var(--primary-color);
    border-radius: 50%;
    animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
    animation-delay: -0.5s;
}

@keyframes animate-preloader {
    0% {
        width: 10px;
        height: 10px;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        opacity: 1;
    }

    100% {
        width: 72px;
        height: 72px;
        top: calc(50% - 36px);
        left: calc(50% - 36px);
        opacity: 0;
    }
}

// Toggle
.dropdown-toggle::after {
    display: inline-block;
    content: '';
    vertical-align: middle;
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    margin-left: 0.255em;
    transition: 0.3s;
}
.show.dropdown-toggle::after {
    transform: rotate(90deg);
}

// custom swiper
.swiper-pagination-bullet {
    cursor: pointer;
}
.swiper-pagination-bullet-active {
    background: var(--primary-color);
}
.swiper-wrapper {
    align-items: center;
}
.overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(3px);
    z-index: 10000;
}

.error-message {
    display: flex;
    align-items: center;
    font-family: var(--font-bold);
    justify-content: center;
}

.fancybox__caption{
    text-align: center;
}