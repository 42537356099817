$primary-color: var(--primary-color);

.sub-product {
    width: 100%;
    &:nth-of-type(odd) {
        background: var(--third-color);
    }
}
.title {
    display: flex;
    align-items: center;
}
.title-product {
    width: 80%;
    margin: 0 auto;
    .sub-title {
        text-align: justify;
    }
}
.h2-custom {
    margin-bottom: 2rem;
    font-size: 2.7rem;
    strong {
        color: #000;
        text-transform: uppercase;
        font-family: var(--font-bold);
        border-bottom: 3px solid  $primary-color;
        &:hover {
            color: $primary-color;
        }
    }
}

.product-img figure {
    position: relative;
    top: 60px;
}
@media (max-width: 1399px) {
    .h2-custom {
        font-size: 2.5rem;
    }
}
@media (max-width: 1206px) {
    .h2-custom {
        font-size: 2.1rem;
    }
}
@media (max-width: 992px) {
    .sub-product {
        margin-bottom: 50px;
        font-size: 15px;
    }
    .product-img figure {
        top: 45px;
    }
    .h2-custom {
        font-size: 1.5em;
    }
}

@media (max-width: 768px) {
    .sub-product {
        overflow: hidden;
        margin-bottom: 20px;
        &:nth-of-type(even) .custom-row {
            flex-direction: column-reverse;
        }
    }
    .h2-custom {
        font-size: 2em;
    }
    .title {
        padding-bottom: 30px;
        margin-top: 10px;
    }
    .product-img figure {
        position: unset;
    }
}

@media (max-width: 475px) {
    .h2-custom {
        font-size: 18px;
    }
}
@media (max-width: 385px) {
    .h2-custom {
        font-size: 16px;
    }
}
