.h2-custom{
    text-align: center;
    text-transform: uppercase;
    font-size: 2.5em;
    color: #5b5b5b;
    letter-spacing: 1px;
    margin-bottom: 1em;
}
.parner{
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    overflow: clip;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}
.image{
    width: 8em;
    object-fit: contain;
    display: flex;
    margin: auto;
}

@media (max-width: 768px) {
    .h2-custom{
        font-size: 2em;
    }
    .image{
        width: 4em;
    }
}

@media (max-width: 475px) {
    .h2-custom{
        font-size: 1.2em;
    }
    .image{
        width: 3em;
    }
}