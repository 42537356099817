.banner {
    position: relative;
}
.banner-img {
    height: calc(100vh - 78px);
    position: relative;
}
.banner-title {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    text-align: center;
    color: #cecece;
    z-index: 1;
    .h1-custom {
        font-size: 2.5em;
        span,
        strong {
            text-transform: uppercase;
        }
    }
}

@media (max-width: 992px) {
    .banner-img {
        height: calc(100vh - 70px);
    }
    .banner-title .h1-custom {
        font-size: 1.8em;
    }
}
@media (max-width: 640px) {
    .banner-title {
        .h1-custom {
            font-size: 1.8em;
        }
    }
    .banner-title .h1-custom {
        font-size: 1.5em;
    }
}
@media (max-width: 465px) {
    .banner-title {
        .h1-custom {
            font-size: 1.2em;
        }
    }
}

@media (max-width: 433px) {
    .banner-title {
        .h1-custom {
            font-size: 1.1em;
        }
    }
}

@media (max-width: 401px) {
    .banner-title {
        .h1-custom {
            font-size: 1em;
        }
    }
}
@media (max-width: 341px) {
    .banner-title {
        .h1-custom {
            font-size: 0.9em;
        }
    }
}
@media (max-width: 321px) {
    .banner-title {
        .h1-custom {
            font-size: 0.8em;
        }
    }
}
