.go-to-top-button {
    position: fixed;
    opacity: 1;
    right: 22px;
    bottom: 15px;
    z-index: 998;
    background: #1ec412;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
    cursor: pointer;
    img {
        width: 70%;
    }
}

@media (max-width: 475px) {
    .go-to-top-button {
        width: 35px;
        height: 35px;
        right: 10px;
    }
}
