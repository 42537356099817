$custom-text-color: var(--text-color);
$custom-text-color-backgroud-hover: var(--primary-color);
$custom-font: var(--third-bold);

.nav-item {
    padding: 0 12px;
    text-align: center;
    font-size: 1em;
    .sub-nav-link {
        color: $custom-text-color;
        font-family: $custom-font;
        font-weight: 600;
        &:hover {
            color: $custom-text-color-backgroud-hover;
        }
    }
}
.navbar-menu {
    border: unset;
    padding: 0;
    color: #000;
    font-size: 15px;
    font-family: $custom-font;
    background: #faf8f9;
    .sub-dropdown-item {
        transition: all 0.2s ease;
        font-weight: 60;
        border: unset;
        padding-left: 15px;
        &:hover {
            background: $custom-text-color-backgroud-hover;
            color: #fff;
        }
    }
}

@media screen and (min-width: 1650px) 
{
    .nav-item{
        padding: 0 15px;
        font-size: 24px;
    }
    .navbar-menu{
        font-size: 22px;
        .sub-dropdown-item{
            padding-left: 20px;
        }
    }
}
