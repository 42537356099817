$background-image: url(https://res.cloudinary.com/dpv6zcovx/image/upload/v1697725256/banner_gkt3ec.jpg);
$primary-color: var(--primary-color);
.popup-tu-van {
    position: fixed;
    z-index: 10001;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    display: flex;
    flex-direction: column;
    background-image: $background-image;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    overflow: hidden;
}
.logo-popup-tu-van {
    padding-top: 10px;
    margin-bottom: 30px;
    .logo-tu-van {
        width: auto;
        height: 40px;
    }
}
.form-tu-van p {
    font-size: 15px;
    text-align: center;
    margin: 10px;
    letter-spacing: 1.5px;
}
.modal-btn-close {
    position: absolute;
    top: 15px;
    right: 20px;
    height: 17px;
    width: 17px;
    cursor: pointer;
    .line-1,
    .line-2 {
        height: 3px;
        width: 100%;
        border-radius: 55px;
        background-color: #fff;
        position: absolute;
        transition: all 0.5s ease;
        top: 50%;
    }
    .line-1 {
        transform: rotate(45deg);
    }
    .line-2 {
        transform: rotate(-45deg);
    }
    &:hover {
        .line-1,
        .line-2 {
            background-color: $primary-color;
        }
    }
}
.title-popup {
    color: #50dd46;
    text-transform: uppercase;
    font-family: var(--font-bold);
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 1.25rem;
    padding: 0 10px;
}

.form-tu-van {
    background: #3a3a3a;
    opacity: 0.9;
    height: 100%;
    max-width: 90%;
    border-radius: 15px;
    margin-bottom: 10px;
}

.info {
    &:first-of-type {
        margin-bottom: 10px;
        padding-bottom: 5px;
    }
    &:last-of-type {
        padding: 10px;
    }
}
.form-sub {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 0.9em;
    padding: 0 35px 0 5px;
    color: #fff;
    cursor: pointer;
}

.form-info-item {
    position: relative;
    margin-top: 10px;
    border-bottom: 2px solid #fff;
    &:last-of-type {
        padding-left: 0;
        padding-right: 0;
    }
    label {
        left: 0;
        font-weight: 400;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 0.9em;
        pointer-events: none;
        transition: 500ms linear;
    }
}
input:focus ~ label,
input:valid ~ label {
    top: -8px;
    color: var(--primary-color);
}
.label-mobile {
    display: none;
}
.info-form-2:last-of-type {
    margin: auto;
}
.dropdown-contact {
    width: 80%;
    text-align: left;
    padding-left: 4px;
    position: relative;
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdowm-form {
    width: 80%;
    font-size: 0.9em;
    cursor: pointer;
    overflow: auto;
    max-height: 145px;
    &::-webkit-scrollbar {
        width: 0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: darkgrey; /* Đổi màu của thanh cuộn */
    }
}
.chose-contact {
    text-transform: lowercase;
    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

.btn-submit {
    padding: 30px 0;
    margin: 0 auto;
    border: none;
    outline: none;
}
.button-form {
    padding: 12px 15px;
    color: #000000;
    background-color: var(--third-color);
    border-radius: 20px;
    font-size: 15px;
    cursor: pointer;
    border: none;
    font-family: var(--font-bold);
    transition: 0.3s;
    &:disabled {
        color: #585050;
        background-color: #a6ad9c;
        &:hover {
            background-color: #a6ad9c;
            color: #585050;
        }
    }
    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

.invalid-form {
    font-size: 0.8em;
    color: #ff424f;
}

@media (min-width: 1440px) {
    .popup-tu-van {
        width: 50vw;
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .title-popup {
        font-size: 1.5em;
    }
}

@media (max-width: 992px) {
    .form-tu-van {
        letter-spacing: 1.3px;
    }
    .logo-popup-tu-van {
        margin-bottom: 20px;
    }
}
@media (max-width: 768px) {
    .popip-contact {
        font-size: 14px;
    }
    .popup-tu-van {
        width: 90vw;
    }
}

@media (max-width: 575px) {
    .popup-contact {
        font-size: 13px;
    }
    .info:first-of-type {
        margin-bottom: unset;
        padding-bottom: unset;
    }
    .logo-popup-tu-van .logo-tu-van {
        width: auto;
        height: 30px;
    }
    .title-popup {
        font-size: 1.5em;
    }
    .form-tu-van p {
        font-size: 1em;
        text-align: center;
        margin: 5px;
        letter-spacing: 1.3px;
    }
    .dropdown-contact {
        width: 100%;
    }
    .dropdowm-form {
        width: 100%;
        height: 8em;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey; /* Đổi màu của thanh cuộn */
        }
    }
    .popup-tu-van {
        width: 95vw;
    }
    .form-tu-van {
        max-width: 95%;
    }
    .btn-submit {
        padding: 15px 0 20px 0;
    }
    .button-form {
        padding: 10px 15px;
        border-radius: 15px;
        font-size: 1em;
    }
}

@media (max-width: 415px) {
    .label-mobile {
        display: block;
    }
    .label-pc {
        display: none;
    }
}
