$text-bold: var(--font-bold);
$primary-color: var(--primary-color);
.service-packaging {
    margin-top: 3em;
    p {
        margin-bottom: 1em;
    }
}
.content-service {
    .blox-icon-title {
        display: flex;
        gap: 1em;
        img {
            width: 50px;
            height: auto;
        }
        h3 {
            font-size: 1.7em;
            width: 100%;
            border-bottom: 2px solid #000;
        }
    }
}
.description-usage-instructions {
    margin-top: 1.5em;
    text-align: justify;
    font-size: 16px;
}
.image-service {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
    .sub-image-service {
        width: 60%;
        margin: auto;
        border-radius: 10px;
    }
}
.content-service-2 {
    margin-top: 3em;
    overflow: hidden;
}
.content-conversion-service {
    margin-bottom: 3em;
}
.conversion-service {
    background: #82ae41;
    text-align: center;
    font-size: 0.9em;
    font-family: $text-bold;
    padding: 4rem 0;
    text-transform: uppercase;
    border-radius: 7px;
    cursor: pointer;
    border: 2px solid #212121;
    letter-spacing: 0.1em;
    transition: 0.5s;
    position: relative;
    white-space: nowrap;
    &:hover {
        letter-spacing: 0.2em;
        opacity: 0.9;
        color: #fff;
        i::before {
            width: 40px;
            left: 20%;
        }
        i::after {
            width: 40px;
            left: 80%;
        }
    }
    i {
        position: absolute;
        inset: 0;
        display: block;
        &::before {
            content: ' ';
            position: absolute;
            top: -2px;
            left: 80%;
            width: 20px;
            height: 2px;
            background: #f8f8f8;
            transform: translateX(-50%) skewX(325deg);
            transition: 0.5s;
        }
        &::after {
            content: ' ';
            position: absolute;
            bottom: -2px;
            left: 20%;
            width: 20px;
            height: 2px;
            background: #f8f8f8;
            transform: translateX(-50%) skewX(325deg);
            transition: 0.5s;
        }
    }
    &.active {
        letter-spacing: 0.2em;
        opacity: 1;
        background: var(--primary-color);
        color: #fff;
    }
}
.conversion-service-show.active {
    display: block;
    transition: 0.5s;
    animation: moving 0.5s ease;
}
@keyframes moving {
    from {
        transform: translateX(100px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@media (max-width: 992px) {
    .conversion-service {
        white-space: unset;
        padding-left: 10px;
        padding-right: 10px;
    }
    .image-service {
        .sub-image-service {
            width: 100%;
        }
    }
}

@media (max-width: 640px) {
    .conversion-service {
        padding: 3rem 10px;
    }
    .content-service .blox-icon-title h3 {
        font-size: 1.5em;
    }
}

@media (max-width: 550px) {
    .conversion-service {
        padding: 3rem 10px;
        font-size: 10px;
    }
    .content-service {
        .blox-icon-title {
            gap: 0.5em;
            img {
                width: 40px;
                height: auto;
            }
            h3 {
                font-size: 1.2em;
                margin-right: 10px;
            }
        }
    }
    .description-usage-instructions {
        margin-top: 10px;
        text-align: justify;
        font-size: 15px;
    }
    .content-service-2 {
        margin-top: 2em;
    }
    .content-conversion-service {
        margin-bottom: 2em;
    }
}

@media (max-width: 433px) {
    .service-packaging {
        margin-top: 2rem;
    }
    .conversion-service {
        padding: 2rem 5px;
        font-size: 10px;
    }
    .content-service {
        .blox-icon-title {
            img {
                width: 35px;
                height: auto;
            }
            h3 {
                font-size: 1em;
            }
        }
    }
    .description-usage-instructions {
        margin-top: 10px;
        text-align: justify;
        font-size: 14px;
    }
}

@media (max-width: 401px) {
    .conversion-service {
        padding: 2rem 2px;
        font-size: 8.5px;
    }
    .content-service {
        .blox-icon-title {
            gap: 0.2em;
            img {
                width: 35px;
                height: auto;
            }
            h3 {
                font-size: 1em;
            }
        }
    }
    .description-usage-instructions {
        margin-top: 10px;
        text-align: justify;
        font-size: 13px;
    }
}
@media (max-width: 401px) {
    .conversion-service {
        padding: 2rem 3px;
        font-size: 8.5px;
    }
    .content-service {
        .blox-icon-title {
            img {
                width: 35px;
                height: auto;
            }
            h3 {
                font-size: 0.9em;
            }
        }
    }
    .description-usage-instructions {
        margin-top: 10px;
        text-align: justify;
        font-size: 13px;
    }
}

@media (max-width: 341px) {
    .conversion-service {
        padding: 2rem 2px;
        font-size: 7.5px;
    }
    .content-service {
        .blox-icon-title {
            img {
                width: 30px;
                height: auto;
            }
            h3 {
                font-size: 0.8em;
            }
        }
    }
}
