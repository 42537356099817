.banner-usage-instructions {
    position: relative;
    .overlay-usage-instructions {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    & > h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
    }
}
.blox-usage-instructions {
    margin-top: 2em;
}
.blox-icon-title img {
    width: 50px;
    height: auto;
}
.blox-icon-title > h2 {
    font-size: 1.8em;
    width: 100%;
    border-bottom: 2px solid #000;
}
.blox-icon-title {
    display: flex;
    gap: 1em;
}
.description-usage-instructions {
    margin-top: 1.5em;
    text-align: justify;
    font-size: 16px;
}

@media (max-width: 1200px) {
    .banner-usage-instructions > h1{
        font-size: 2em;
    }
}

@media (max-width: 768px) {
    .banner-usage-instructions > h1{
        font-size: 1.5em;
    }
    .blox-icon-title > h2 {
        font-size: 1.5em;
    }
    .blox-usage-instructions {
        margin-top: 1em;
    }
    .description-usage-instructions {
        margin-top: 1em;
    }
}

@media (max-width: 481px) {
    .banner-usage-instructions > h1{
        font-size: 1.5em;
    }
    .blox-icon-title{
        gap: 0.5em;
    }
    .blox-icon-title > h2 {
        font-size: 1.2em;
    }
    .blox-icon-title img {
        width: 40px;
        height: auto;
    }
    .blox-usage-instructions {
        margin-top: 1em;
    }
    .description-usage-instructions {
        margin-top: 1em;
    }
}

@media (max-width: 400px) {
    .banner-usage-instructions > h1{
        font-size: 1.3em;
    }
    .blox-icon-title{
        gap: 0.5em;
    }
    .blox-icon-title > h2 {
        font-size: 1.1em;
    }
    .blox-icon-title img {
        width: 40px;
        height: auto;
    }
    .blox-usage-instructions {
        margin-top: 1em;
    }
    .description-usage-instructions {
        margin-top: 1em;
    }
    .description-usage-instructions {
        font-size: 14px;
    }
}